import React, { useEffect, useState } from "react";
import { Text, Transformer } from "react-konva";

const TextAndInput = ({
  index,
  data,
  listData,
  setListData,
  setPosition,
  setSelectedInputId,
  setDidEdit,
  selectedInputId,
  isSelected,
  setIselected,
  setTextColor,
  dataSelectedObj,
  setDataSelectedObj,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(() => {
    if (isSelected && data.id === selectedInputId) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedInputId]);

  const onMoveText = (e) => {
    const node = shapeRef.current;
    const CopyOfData = listData;
    let foundIndex = listData.findIndex((x) => x.id == data.id);
    CopyOfData[foundIndex] = {
      ...listData[foundIndex],
      x: node.getX(),
      y: node.getY(),
    };
    setListData([...CopyOfData]);
  };

  const getStyleDynamic = () => {
    let style = "";
    Object.entries(data).map(([k,v]) => {
      if(v){
        switch (k) {
          case "i":
            style= style+ " italic ";
            break;
          case "b":
            style = style+ " bold ";
            break;
          default:
            break;
        }
      }
    });
    return style;
  };
  console.log("trRef",data.height, " wid ", data.width)
  return (
    <>
      <Text
        key={index}
        text={data.title}
        fill={data.color}
        x={data.x}
        y={data.y}
        draggable
        onDragMove={(e) => onMoveText(e)}
        fontSize={data.size}
        fontFamily={data.fontFamilly}
        height={data.height}
        width={data.width}
        fontStyle={getStyleDynamic()}
        textDecoration={data.u ? "underline" : ""}
        onClick={(e) => {
          setIselected(true);
          setSelectedInputId(data.id);
          setDataSelectedObj(data);
          const getTextToResizeObj = listData.find(
            (e) => e.id === selectedInputId
          );
          setTextColor(getTextToResizeObj.color ? data.color : "#000");
          console.log("e", e);
        }}
        onDblClick={(e) => {
          console.log("e", e);
          setDidEdit(true);
          setPosition({ x: e.evt.clientY, y: e.evt.clientX });
          setSelectedInputId(data.id);
        }}
        ref={shapeRef}
        onTransformStart={() => {
          setSelectedInputId(data.id);
          setIselected(true);
        }}
        onTransformEnd={(e) => {
          setSelectedInputId(data.id);
          console.log(e)
          console.log(selectedInputId === data.id);
          if (selectedInputId === data.id) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);

            const getTextToResizeObj = listData.find(
              (e) => e.id === selectedInputId
            );
            const copyData = listData.filter((e) => e.id !== selectedInputId);
            //debugger
            //console.log(node.width(), "   ",node.height())
            setListData([
              ...copyData,
              {
                ...getTextToResizeObj,
                x: node.x(),
                y: node.y(),
                // width: Math.max(5, node.width() * scaleX),
                //height: Math.max(node.height() * scaleX, 12) ,
                // font: node.fontSize() * scaleX,
                width: Math.max(node.width() * scaleX, 20),
              },
            ]);
          }
        }}
      />
      {isSelected && selectedInputId === data.id && (
        <Transformer
          ref={trRef}
          enabledAnchors={["middle-left","middle-right"]}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TextAndInput;
