import React, { useEffect, useState } from "react";
import { Image, Transformer } from "react-konva";

const ImageComponenet = ({
  listData,
  setListData,
  id,
  data,
  setSelectedInputId,
  selectedInputId,
  isSelected,
  setIselected,
  setDataSelectedObj
}) => {
  const [img, setImg] = useState(null);

  useEffect(() => {
    const newImage = new window.Image();
    newImage.src =
      "https://katomi.co/assets/img/logo/katomi.png";
    newImage.onload = () => {
      // setState will redraw layer
      // because "image" property is changed
      setImg(newImage);
    };
  }, []);

  const shapeRef = React.useRef();
  const trRef = React.useRef();
  useEffect(() => {
    if (isSelected && data.id === selectedInputId) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedInputId]);

  return (
    <>
      <Image
        onDragMove={(e) => {
          console.log("we are dragging image", e);
          const node = shapeRef.current;
          const CopyOfData = listData;
          let foundIndex = listData.findIndex((x) => x.id == data.id);
          CopyOfData[foundIndex] = {
            ...listData[foundIndex],
            x:node.getX(),
            y:node.getY(),
          };
          setListData([...CopyOfData]);
        }}
        draggable
        opacity={data.opacity/10}
        image={img}
        x={data.x}
        y={data.y}
        width={data.width}
        height={data.height}
        onTransformEnd={(e) => {
          setSelectedInputId(data.id);
          if (selectedInputId === data.id) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);

            const getImageObj = listData.find((e) => e.id === selectedInputId);
            const copyData = listData.filter((e) => e.id !== selectedInputId);
            setListData([
              ...copyData,
              {
                ...getImageObj,
                x: node.x(),
                y: node.y(),
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              },
            ]);
          }
        }}
        visible={true}
        onDblClick={(e) => {
          console.log(e);
          setListData([...listData.filter((e) => e.id !== id)]);
        }}
        //   ref={node => {
        //     this.imageNode = node;
        //   }}
        ref={shapeRef}
        onClick={(e) => {
          setIselected(true);
          setSelectedInputId(data.id);
          setDataSelectedObj(data);
        }}
      />
      {isSelected && selectedInputId === data.id && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default ImageComponenet;
