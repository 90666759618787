import { Editor } from "react-draft-wysiwyg";
import React, { useEffect } from "react";

const RedactionEditor = ({ editorState, editRef,setEditorState }) => {
    useEffect(() => {
        function deleteItemsFromDom(className){
          const elems = document.getElementsByClassName(className)
          if(className==="rdw-inline-wrapper"){
            const nestedElem= document.getElementsByClassName('rdw-option-wrapper') 
           if(nestedElem.length === 13){
            let i=0;
            while(i<=4){
              i+=1;
              nestedElem[2].parentNode.removeChild(nestedElem[2]);
            }
           }
          }else{
            while(elems.length > 0){
              elems[0].parentNode.removeChild(elems[0]);
          }
          }
        }
        const items = ["rdw-fontfamily-wrapper","rdw-fontsize-wrapper",'rdw-link-wrapper','rdw-list-wrapper','rdw-colorpicker-wrapper','rdw-embedded-wrapper','rdw-emoji-wrapper','rdw-image-wrapper','rdw-remove-wrapper','rdw-inline-wrapper']
        let i = 0
        while(items.length> 0){
          i++;
          console.log(i, items.length)
            if(items[i] ===  "rdw-inline-wrapper"){
              deleteItemsFromDom(items[0])
              items.shift()

            }else{
              deleteItemsFromDom(items[0])
              items.shift()
  
            }
        }
    },[])
    
    const onEditorStateChange = (event) => {
        setEditorState(event);
        try {
        } catch (error) {
          console.log("error", error);
        }
      };
  return (
    <>
      <div>
        <h4>Rédaction du compte rendu ou préscréption</h4>
      </div>
      <div style={{ maxWidth: "50%" }}>
        <Editor
          editorState={editorState}
          wrapperClassName="consultation-editor"
          editorClassName="consultation-editor "
          onEditorStateChange={onEditorStateChange}
          placeholder="Rédiger votre avis ici ..."
          editorStyle={{ height: "250px", padding: "8px" }}
          id={"html2canvas-container"}
          ref={editRef}
          stripPastedStyles
        />
      </div>
    </>
  );
};

export default RedactionEditor;
