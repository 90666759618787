import React from "react";

const StyleComponent = ({ listData, setListData, selectedInputId }) => {
  const changeStyle = (target) => {
    const getTextObj = listData.find((e) => e.id === selectedInputId);
    const copyData = listData.filter((e) => e.id !== selectedInputId);
    setListData([
      ...copyData,
      {
        ...getTextObj,
        [target]: !getTextObj[target],
      },
    ]);
  };
  return (
    <>
      <button
        style={{ width: "40px", height: "40px" }}
        onClick={() => changeStyle("i")}
      >
        I
      </button>
      <button
        onClick={() => changeStyle("b")}
        style={{ width: "40px", height: "40px" }}
      >
        B
      </button>
      <button
        onClick={() => changeStyle("u")}
        style={{ width: "40px", height: "40px" }}
      >
        U
      </button>
    </>
  );
};

export default StyleComponent;
