import JsBarcode from "jsbarcode";
import { v4 as uuidv4 } from "uuid";
import QRCode from "qrcode";
import DoctorInfo from "../DoctorInfo.json";
import { SPACING_FONT_SIZE_By_Y } from "./constants";

export const AddItemToCanvas = (item, listData, setListData, setUrlCodeQR) => {
  if (item.type === "QrCode") {
    if (!listData.find((e) => e.type === "QrCode")) {
      setListData([
        ...listData,
        {
          ...item,
          id: uuidv4(),
          b64: textToBase64qrcode("sample text", setUrlCodeQR),
        },
      ]);
    } else {
      alert(`You can only add 1 ${item.type}`);
    }
  } else if (item.type === "redaction") {
    if (item.spec === "end") {
      if (!listData.find((e) => item.spec === e.spec && item.type === e.type)) {
        setListData([
          ...listData,
          {
            ...item,
            id: uuidv4(),
          },
        ]);
      } else {
        alert(`You can only add 1 ${item.type}`);
      }
    } else {
      if (!listData.find((e) => item.spec === e.spec && item.type === e.type)) {
        setListData([
          ...listData,
          {
            ...item,
            id: uuidv4(),
          },
        ]);
      } else {
        alert(`You can only add 1 ${item.type}`);
      }
    }
  } else if (item.type === "BarCode") {
    setListData([
      ...listData,
      {
        ...item,
        id: uuidv4(),
        b64: textToBase64Barcode("sample text"),
      },
    ]);
  } else {
    setListData([...listData, { ...item, id: uuidv4() }]);
  }
};
export const TextAreaEdit = (e, setInputValue) => setInputValue(e.target.value);
export const checkDeselect = (
  e,
  setSelectedInputId,
  setIselected,
  setDataSelectedObj
) => {
  const clickedOnEmpty = e.target === e.target.getStage();
  if (clickedOnEmpty) {
    setSelectedInputId("");
    setIselected(false);
    setDataSelectedObj({});
  }
};
export const textToBase64qrcode = (text, setUrlCodeQR) => {
  let canvas = document.createElement("canvas");
  let b64;
  QRCode.toDataURL(canvas, text, function (error) {
    if (error) console.error(error);
    b64 = canvas.toDataURL("image/png");
  });
  setUrlCodeQR(b64);
  return b64;
};
export const textToBase64Barcode = (text) => {
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {
    format: "CODE128",
    displayValue: false,
  });
  const b64 = canvas.toDataURL("image/png");
  return b64;
};
export const handleColorChange = (
  color,
  setTextColor,
  listData,
  selectedInputId,
  setListData
) => {
  setTextColor(color.hex);
  const getTextToResizeObj = listData.find((e) => e.id === selectedInputId);
  const copyData = listData.filter((e) => e.id !== selectedInputId);
  setListData([
    ...copyData,
    {
      ...getTextToResizeObj,
      color: color.hex,
    },
  ]);
};
export const printCharacters = (
  doc,
  textObject,
  startY,
  startX,
  fontSize,
  lineSpacing,
  data,
  text,
  //sumRows,
  listData,
  sharedY
) => {
  let options = {};
  console.log("dataPRINT", data);
  switch (data["text-align"]) {
    case data["text-align"] === "center":
      options = {
        ...options,
        align: "center",
      };
      break;
    case data["text-align"] === "right":
      options = {
        ...options,
        align: "right",
      };
      break;
    default:
      break;
  }

  const startXCached = startX;
  let startYCached = startY;
  const boldStr = "bold";
  const normalStr = "normal";

  textObject.map((row, nRowIndex) => {
    //totalRows += 1;
    //debugger
    console.log("textObject,Row", text);
    let widthRow = doc.getTextWidth(text);
    if (data["text-align"] === "center") {
      //debugger
      startX = (doc.internal.pageSize.width - widthRow) / 2;
    }

    Object.entries(row).map(([key, value]) => {
      doc.setFont(
        "Arial",
        value.italic ? "italic" : normalStr,
        value.bold ? boldStr : normalStr
      );
      console.log("doc.getStringUnitWidth(value.char)", {
        wid: doc.getStringUnitWidth(value.char),
        char: value.char,
      });
      doc.setFontSize(12).text(value.char, startX, startYCached);
      startX = startX + doc.getStringUnitWidth(value.char) * 4.67;
    });
    startX = startXCached;
    startYCached += lineSpacing;
  });
  //return {startY}
};
export const ItemKonvaToJsPDF = (itemPDF, multiplier, pdf, numberPage) => {

  itemPDF.map(async (e) => {
    if (e.type === "text") {
      debugger;
      let stringFromJson = "";
      e.title.split(" ").map((t) => {
        if (t.includes("{{") && t.includes("}}") && t.includes(".")) {
          const splitByDot = t.replace("{{", "").replace("}}", "").split(".");
          stringFromJson += DoctorInfo[splitByDot[0]][splitByDot[1]] + " ";
        } else if (t.includes("{{") && t.includes("}}")) {
          stringFromJson +=
            DoctorInfo[t.replace("{{", "").replace("}}", "")] + " ";
        } else {
          stringFromJson += t + " ";
        }
      });
      stringFromJson.trim();
      const splitTextMarks = pdf.splitTextToSize(
        stringFromJson,
        e.width / multiplier,
        {
          fontSize: e.size,
        }
      );
      let position = 0;
      let charsMapLength = 0;
      const mapRows = splitTextMarks.map((row, i) => {
        const splitRow = row.split("");
        const chars = splitRow.map((char, j) => {
          position = charsMapLength + j + i;
          let currentChar = stringFromJson.charAt(position);
          console.log("currentChar", currentChar);
          return { char: currentChar };
        });
        charsMapLength += splitRow.length;
        return { ...chars };
      });
      let startX = e.x;
      let startXCached = e.x;
      let startY = e.y;
      debugger;
      /* switch(e.size){
        case 32:
          if(e.y<25) startY=26
          break;
        case 28:
          if(e.y<20) startY=22
          break;
          case 28:
            if(e.y<16) startY=16
            break;
            case 24:
              if(e.y<18) startY=18
              break;
              case 20:
                if(e.y<20) startY=9
                break;
        default:
          break;
      }*/
      console.log("satay", startY);
      debugger;
      if (e.repeatText) {
        mapRows.map((row) => {
          Object.entries(row).map(([key, value]) => {
            pdf.setFontSize(e.size ? Math.trunc(e.size) : 12);
            pdf.setTextColor(e.color ? e.color : "#000");

           if(e.fontFamilly === "Ubuntu"){
            pdf.addFont(
              "/fonts/Ubuntu-Regular.ttf",
              "Ubuntu-Regular",
              "Ubuntu-Regular-normal",
              "normal"
            );
            debugger
           }else if(e.fontFamilly ==="EduNSWACTFoundation"){
            pdf.addFont(
              "EduNSWACTFoundation-Regular.ttf",
              "EduNSWACTFoundation",
              "normal"
            );
            debugger
           }else if(e.fontFamilly==="DancingScript"){
            pdf.addFont(
              "DancingScript-VariableFont_wght.ttf",
              "DancingScript-VariableFont_wght",
              "normal"
            );
           }

            pdf
              .setFont(
                e.fontFamilly ? e.fontFamilly : "Arial",
                e.i ? "italic" : "",
                e.b ? "bold" : ""
              )
              const textH = pdf.getLineHeight();
              const lineY = startY + textH / 2;
  
            pdf.text(startX / multiplier, lineY / multiplier, value.char);
            //after color reset
            pdf.setTextColor("#000");
            //pdf.text(value.char, startX, startY);
            startX = startX + pdf.getStringUnitWidth(value.char) * e.size;
          });
          startX = startXCached;
          startY += SPACING_FONT_SIZE_By_Y[e.size];
        });
        pdf
        .setFont("Arial","","")
        pdf.setTextColor("#000");
      } else if (!e.repeatText && numberPage < 1) {
        mapRows.map((row) => {
          Object.entries(row).map(([key, value]) => {
            pdf.setFontSize(e.size ? Math.trunc(e.size) : 12);
            pdf.setTextColor(e.color ? e.color : "#000");
            pdf
              .setFont(
                e.fontFamilly ? e.fontFamilly : "Arial",
                e.i ? "italic" : "",
                e.bold ? "bold" : ""
              )
              .text(startX / multiplier, startY / multiplier, value.char);
            //after color reset
            pdf.setTextColor("#000");
            //pdf.text(value.char, startX, startY);
            startX = startX + pdf.getStringUnitWidth(value.char) * e.size;
          });
          startX = startXCached;
          startY += 10;
        });
        //after color reset
        pdf.setTextColor("#000");
      }
    } else if (e.type === "QrCode" || e.type === "BarCode") {
      pdf.addImage(
        e.b64,
        "png",
        e.x / multiplier,
        e.y / multiplier,
        e.width / multiplier,
        e.height / multiplier
      );
    } else if (e.type === "image") {
      const corsImageModified = new Image();
      corsImageModified.crossOrigin = "Anonymous";
      corsImageModified.src = e.url;
      pdf.saveGraphicsState();
      pdf.setGState(new pdf.GState({ opacity: e.opacity / 10 }));
      pdf.addImage(
        corsImageModified,
        "PNG",
        e.x / multiplier,
        e.y / multiplier,
        e.width / multiplier,
        e.height / multiplier
      );
      pdf.restoreGraphicsState();
    } else {
      if (e.type !== "redaction") {
        pdf.setFontSize(e.font ? Math.trunc(e.font) : 12);
        pdf.text(e.x / multiplier, e.y / multiplier, e.title);
      }
    }
  });
};
