import React, { useEffect, useState } from "react";
import { Line, Text, Transformer } from "react-konva";

const RedactionComponent = ({
  index,
  data,
  listData,
  setListData,
  setSelectedInputId,
  selectedInputId,
  isSelected,
  setDataSelectedObj,
  color,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(() => {
    if (isSelected && data.id === selectedInputId) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedInputId]);

  const onMoveText = (e) => {
    const node = shapeRef.current;
    const CopyOfData = listData;
    let foundIndex = listData.findIndex((x) => x.id == data.id);
    CopyOfData[foundIndex] = {
      ...listData[foundIndex],
      y: node.getY(),
    };
    setListData([...CopyOfData]);
  };

  return (
    <>
      <Line
        dragBoundFunc={(pos) => {
          if(pos.y%10===0){
            return {
              x: 10,
              y: pos.y,
            };
          }else{
            let div = Math.round(pos.y/10);
            return {
              x: 10,
              y: div*10,
            };
          }

        }}
        x={10}
        y={data.y}
        draggable={true}
        onDragMove={(e) => onMoveText(e)}
        onClick={(e) => {
          //setIselected(true);
          setSelectedInputId(data.id);
          setDataSelectedObj(data);
          console.log("e", e);
        }}
        ref={shapeRef}
        stroke={color}
        width={200}
        tension={1}
        strokeWidth={5}
        points={[0, 0, 610, 0]}
      />
    </>
  );
};

export default RedactionComponent;
