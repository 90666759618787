// import logo from "./logo.svg";
import "./App.css";
import { Stage, Layer } from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import ImageComponenet from "./ImageComponent";
import "./App.css";
import TextAndInput from "./TextComponent";
import QrCodeGenerator from "./QrCodeGenetrator";
import BarCodeGenerator from "./BarCodeGenerator";
import DoctorDataJson from "./DoctorInfo.json";
import ItemsArrayJson from "./ItemArray.json";
import DataBaseKeysJson from "./DataBaseKeys.json";
import { ChromePicker } from "react-color";
import StyleComponent from "./StyleComponent";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RedactionComponent from "./RedactionComponent";
import RedactionEditor from "./redactionEditor";
import DataToJSPDFComponent from "./pdfToJsPdfButtons";
import {
  AddItemToCanvas,
  checkDeselect,
  handleColorChange,
  printCharacters,
  TextAreaEdit,
} from "./Helper/functions";
import { v4 as uuidv4 } from "uuid";
import { POSSIBLE_FONT_FAMILLY } from "./Helper/constants";

const cover = {
  marginTop: "20px",
  marginRight: "20px",
};
const fontArray = [14, 16, 20, 24, 28, 32];
const App = () => {
  const mainContainer = useRef();
  const stageRef = useRef();
  const editRef = useRef();
  const [listData, setListData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [postion, setPosition] = useState({ x: 0, y: 0 });
  //const [selectedItemId, setSelectedItemId] = useState({ x: 0, y: 0 });
  const [selectedInputId, setSelectedInputId] = useState("");
  const [didEdit, setDidEdit] = useState(false);
  const [isSelected, setIselected] = useState(false);
  const [urlCodeQR, setUrlCodeQR] = useState(false);
  const [ItemsArray, setItemsArrayJson] = useState(ItemsArrayJson);
  //const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [textColor, setTextColor] = useState();
  const [dataSelectedObj, setDataSelectedObj] = useState({});
  const [step, setStep] = useState(0);
  const [editorState, setEditorState] = useState();
  const [sharedX, setSharedX] = useState();
  const [sharedY, setSharedY] = useState();

  useEffect(() => {
    // let collectData = {};
    // Object.entries(DoctorDataJson).map(([key, value], index) => {
    //   switch (key) {
    //     case "addressDetails":
    //       Object.entries(DoctorDataJson[key]).map(([k, v], index) => {
    //         if (v !== "" && v !== undefined) {
    //           collectData = {
    //             ...collectData,
    //             [v]: {
    //               x: 0,
    //               y: 0,
    //               title: v,
    //               name: DataBaseKeysJson[k],
    //               type: "text",
    //               editable: false,
    //               size: 32,
    //             },
    //           };
    //         }
    //       });
    //       break;
    //     case "email":
    //     case "speciality":
    //     case "phoneNumber":
    //     case "firstName":
    //     case "lastName":
    //     case "city":
    //       if (value !== "" && value !== undefined) {
    //         collectData = {
    //           ...collectData,
    //           [key]: {
    //             x: 0,
    //             y: 0,
    //             title: value,
    //             name: DataBaseKeysJson[key],
    //             type: "text",
    //             editable: false,
    //             size: 32,
    //           },
    //         };
    //       }
    //       break;
    //     default:
    //       break;
    //   }
    //   console.log("collectData", collectData);
    // });
    // setItemsArrayJson({
    //   ...ItemsArray,
    //   ...collectData,
    // });
    setListData([
      ...listData,
      {
        ...ItemsArray["redaction"],
        id: uuidv4(),
      },
      {
        ...ItemsArray["endRedaction"],
        id: uuidv4(),
      },
    ]);
  }, []);

  useEffect(() => {
    //console.log("selectedInputId", selectedInputId);
    if (selectedInputId.length > 0 || selectedInputId !== "") {
      const getTypeSelectedItem = listData.find(
        (e) => e.id === selectedInputId
      );
      //console.log("getTypeSelectedItem", getTypeSelectedItem);
      if (getTypeSelectedItem.type === "text") {
        setInputValue(getTypeSelectedItem.title);
      }
    }
  }, [selectedInputId]);

  useEffect(() => {
    if (mainContainer.current) {
      mainContainer.current.canvas.context.canvas.width = 210 * 3;
      mainContainer.current.canvas.context.canvas.height = 297 * 3;
    }
  }, [mainContainer]);

  useEffect(() => {
    setDataSelectedObj({});
    setIselected(false);
    setSelectedInputId("");
  }, [step]);

  useEffect(() => {
    if (
      (selectedInputId === "" ||
        selectedInputId === undefined ||
        selectedInputId === null) &&
      listData.length > 0
    ) {
      setSelectedInputId(listData[listData.length - 1].id);
    }
    const data = listData.find(
      (e) => e.type === "redaction" && e.spec === "add"
    );
    if (data) {
      setSharedY(data.y);
    }
  }, [listData]);

  useEffect(() => {
    if (
      selectedInputId === "" ||
      selectedInputId === undefined ||
      selectedInputId === null
    ) {
      setDidEdit(false);
    }
  }, [selectedInputId]);
  console.log("listData", listData);
  return step === 0 ? (
    <div
      className="App"
      onKeyDown={(e) => {
        if (e.keyCode === 46) {
          if (isSelected && dataSelectedObj !== {}) {
            const getObjToDelete = listData.find(
              (e) => e.id === selectedInputId
            );
            const copyData = listData.filter((e) => e.id !== selectedInputId);
            setListData([...copyData]);
            setSelectedInputId("");
            setIselected(false);
            setDataSelectedObj({});
          }
        }
      }}
      tabIndex="0"
    >
      <div className="MenuContainer">
        <ul className="ListItem">
          {Object.entries(ItemsArray).map(([k, v]) => {
            if (k !== "redaction" && k !== "endRedaction") {
              return (
                <li
                  onClick={(e) => {
                    switch (k) {
                      case "text":
                        AddItemToCanvas(v, listData, setListData);
                        break;
                      case "image":
                        AddItemToCanvas(v, listData, setListData);
                        break;
                      case "QrCode":
                        AddItemToCanvas(v, listData, setListData, setUrlCodeQR);
                        break;
                      case "BarCode":
                        AddItemToCanvas(v, listData, setListData);
                        break;
                      default:
                        AddItemToCanvas(v, listData, setListData);
                        break;
                    }
                  }}
                >
                  <p className="TextListMenu">{v.name}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className="back">
        <Stage
          width={210 * 3}
          height={297 * 3}
          id="stageOfKonva"
          style={{
            backgroundColor: "white",
            maxWidth: "fit-content",
            margin: 10,
          }}
          onMouseDown={(e) =>
            checkDeselect(
              e,
              setSelectedInputId,
              setIselected,
              setDataSelectedObj
            )
          }
          ref={stageRef}
        >
          <Layer ref={mainContainer}>
            {listData.length > 0 &&
              listData.map((data, index) => {
                switch (data.type) {
                  case "text":
                    return (
                      <TextAndInput
                        index={index}
                        data={data}
                        listData={listData}
                        setListData={setListData}
                        setPosition={setPosition}
                        setSelectedInputId={setSelectedInputId}
                        setDidEdit={setDidEdit}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                        setTextColor={setTextColor}
                        dataSelectedObj={dataSelectedObj}
                        setDataSelectedObj={setDataSelectedObj}
                      />
                    );
                  case "image":
                    return (
                      <ImageComponenet
                        listData={listData}
                        setListData={setListData}
                        id={data.id}
                        data={data}
                        setSelectedInputId={setSelectedInputId}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                        setDataSelectedObj={setDataSelectedObj}
                      />
                    );
                  case "QrCode":
                    return (
                      <QrCodeGenerator
                        url={urlCodeQR}
                        id={data.id}
                        index={index}
                        data={data}
                        listData={listData}
                        setListData={setListData}
                        setPosition={setPosition}
                        setSelectedInputId={setSelectedInputId}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                      />
                    );
                  case "BarCode":
                    return (
                      <BarCodeGenerator
                        url={data.b64}
                        id={data.id}
                        index={index}
                        data={data}
                        listData={listData}
                        setListData={setListData}
                        setPosition={setPosition}
                        setSelectedInputId={setSelectedInputId}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                      />
                    );
                  case "endRedaction":
                  case "redaction":
                    return (
                      <RedactionComponent
                        index={index}
                        data={data}
                        listData={listData}
                        setListData={setListData}
                        setPosition={setPosition}
                        setSelectedInputId={setSelectedInputId}
                        setDidEdit={setDidEdit}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                        setTextColor={setTextColor}
                        setDataSelectedObj={setDataSelectedObj}
                        color={
                          data.type === "redaction" && data.spec === "add"
                            ? "green"
                            : "red"
                        }
                      />
                    );
                  //return <Image width={120} height={120} src={urlCodeQR}  x={40} y={40}/>;
                  default:
                    console.log("DATA", data);
                    return (
                      <TextAndInput
                        index={index}
                        data={data}
                        listData={listData}
                        setListData={setListData}
                        setPosition={setPosition}
                        setSelectedInputId={setSelectedInputId}
                        setDidEdit={setDidEdit}
                        selectedInputId={selectedInputId}
                        isSelected={isSelected}
                        setIselected={setIselected}
                      />
                    );
                }
              })}
          </Layer>
        </Stage>
        <textarea
          value={inputValue}
          style={{
            display: didEdit ? "block" : "none",
            position: "absolute",
            top: postion.x + "px",
            left: postion.y + "px",
            width:"350px",
            height:"150px"
          }}
          onChange={(e) => TextAreaEdit(e, setInputValue)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && inputValue.trim().length > 2) {
              console.log("selectedInputId", selectedInputId);
              const Obj = listData.find((e) => e.id === selectedInputId);
              Obj.title = inputValue;
              const newArray = listData.filter((e) => e.id !== Obj.id);
              newArray.push(Obj);
              console.log("new ARRR", newArray);
              setListData(newArray);
              setDidEdit(false);
              setInputValue("");
            }
          }}
        />
        <button
          onClick={(e) => {
            setStep(1);
          }}
          className="btnPDF"
        >
          Save us Json
        </button>
      </div>
      <div style={{ minWidth: "250px", maxWidth: "200px" }}>
        <div style={cover} />
        {dataSelectedObj.type === "image" ? (
          <div>
            <p>Changer l'opaciter de l'image:</p>
            <div>
              <input
                type="range"
                id="opacity"
                name="opacity"
                min="0"
                max="10"
                onChange={(e) => {
                  const CopyOfData = listData;
                  let foundIndex = listData.findIndex(
                    (x) => x.id == dataSelectedObj.id
                  );
                  //debugger
                  CopyOfData[foundIndex] = {
                    ...listData[foundIndex],
                    opacity: parseInt(e.target.value),
                  };
                  setListData([...CopyOfData]);
                }}
              />
              <label for="volume">Opacity</label>
            </div>
          </div>
        ) : null}
        {dataSelectedObj.type === "text" && isSelected && (
          <div>
            <div>
              <ChromePicker
                disableAlpha
                color={textColor}
                onChange={(color) =>
                  handleColorChange(
                    color,
                    setTextColor,
                    listData,
                    selectedInputId,
                    setListData
                  )
                }
              />
            </div>
            <div style={{ marginTop: "20px", marginRight: "70px" }}>
              <StyleComponent
                setListData={setListData}
                listData={listData}
                selectedInputId={selectedInputId}
              />
            </div>
            <div>
              {/* <li style="color:red;font-size:30px">font</li> */}
              <select
                name="font"
                id="setFont"
                style={{ height: 50 }}
                onChange={(e) => {
                  const CopyOfData = listData;
                  let foundIndex = listData.findIndex(
                    (x) => x.id == dataSelectedObj.id
                  );
                  //debugger
                  CopyOfData[foundIndex] = {
                    ...listData[foundIndex],
                    size: parseInt(e.target.value),
                  };
                  setListData([...CopyOfData]);
                }}
              >
                <option value="">--Please choose an option--</option>
                {fontArray.map((e) => {
                  return dataSelectedObj.size === e ? (
                    <option value={e} selected>
                      {e}
                    </option>
                  ) : (
                    <option value={e}>{e}</option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                name="font"
                id="setFont"
                style={{ height: 50 }}
                onChange={(e) => {
                  const CopyOfData = listData;
                  let foundIndex = listData.findIndex(
                    (x) => x.id == dataSelectedObj.id
                  );
                  //debugger
                  CopyOfData[foundIndex] = {
                    ...listData[foundIndex],
                    fontFamilly: e.target.value,
                  };
                  setListData([...CopyOfData]);
                }}
              >
                <option value="arial">Arial</option>
                {POSSIBLE_FONT_FAMILLY.map((e) => {
                  return dataSelectedObj.fontFamilly === e ? (
                    <option value={e} selected>
                      {e}
                    </option>
                  ) : (
                    <option value={e}>{e}</option>
                  );
                })}
              </select>
            </div>
            <div className="repeatText">
              <div>
              {dataSelectedObj.repeatText ? (
                <input
                  name="repeat"
                  id="setFont"
                  type="checkbox"
                  style={{ height: 50 }}
                  value={dataSelectedObj.repeatText}
                  onChange={(e) => {
                    const CopyOfData = listData;
                    let foundIndex = listData.findIndex(
                      (x) => x.id == dataSelectedObj.id
                    );
                    //debugger
                    CopyOfData[foundIndex] = {
                      ...listData[foundIndex],
                      repeatText: false,
                    };
                    setListData([...CopyOfData]);
                    setDataSelectedObj(CopyOfData[foundIndex]);
                  }}
                  checked
                />
              ) : (
                <input
                  name="repeat"
                  id="setFont"
                  type="checkbox"
                  style={{ height: 50 }}
                  value={dataSelectedObj.repeatText}
                  onChange={(e) => {
                    const CopyOfData = listData;
                    let foundIndex = listData.findIndex(
                      (x) => x.id == dataSelectedObj.id
                    );
                    //debugger
                    CopyOfData[foundIndex] = {
                      ...listData[foundIndex],
                      repeatText: true,
                    };
                    console.log(CopyOfData[foundIndex]);
                    setListData([...CopyOfData]);
                    setDataSelectedObj(CopyOfData[foundIndex]);
                  }}
                />
              )}
              </div>
              <div>Répéter ce texte dans tous les pages</div>
            </div>
          </div>
        )}
        {(dataSelectedObj.type === "text" && isSelected && didEdit) && (
          <div>
            <ul className="ListItem">
              {Object.entries(DataBaseKeysJson).map(([k, v]) => {
                return (
                  <li onClick={()=>{
                    setInputValue(`${inputValue +" {{"+k+"}}"}`)
                  }}>
                    <p className="TextListMenu">{v}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        minHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RedactionEditor
        editorState={editorState}
        editRef={editRef}
        setEditorState={setEditorState}
      />
      <DataToJSPDFComponent
        listData={listData}
        editorState={editorState}
        printCharacters={printCharacters}
        sharedY={sharedY}
      />
      <button onClick={(e) => setStep(step - 1)}>Previous</button>
    </div>
  );
};

export default App;
