import React, { useEffect } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

function BarCodeGenerator({
  url,
  id,
  data,
  listData,
  setListData,
  setPosition,
  setSelectedInputId,
  selectedInputId,
  isSelected,
  setIselected,
}) {
  const [image] = useImage(url);
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  useEffect(() => {
    console.log(
      "isSelected && data.id === selectedInputId",
      isSelected && data.id === selectedInputId
    );
    if (isSelected && data.id === selectedInputId) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedInputId]);
  // "image" will DOM image element or undefined
  // console.log("YOUR ID QR CODE",id)
  // console.log("selectedInputId",selectedInputId)
  return (
    <>
      <Image
        ref={shapeRef}
        image={image}
        draggable
        width={data.width}
        height={data.height}
        x={data.x}
        y={data.y}
        onClick={(e) => {
          setSelectedInputId(id);
          setIselected(true);
        }}
        onDragStart={() => {
          setSelectedInputId(id);
        }}
        key={data.id}
        onDragEnd={(e) => {
          //const { x, y } = e.target.getStage().getPointerPosition();
          const node = shapeRef.current;
          console.log(selectedInputId);
          const getQrCodeObj = listData.find((e) => e.id === selectedInputId);
          const copyData = listData.filter((e) => e.id !== selectedInputId);
          setListData([
            ...copyData,
            {
              ...getQrCodeObj,
              x:node.getX(),
              y:node.getY(),
            },
          ]);
        }}
        onTransformStart={() => {
          setSelectedInputId(data.id);
          setIselected(true);
        }}
        onTransformEnd={(e) => {
          setSelectedInputId(data.id);
          if (selectedInputId === data.id) {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);
            const getTextToResizeObj = listData.find(
              (e) => e.id === selectedInputId
            );
            const copyData = listData.filter((e) => e.id !== selectedInputId);
            setListData([
              ...copyData,
              {
                ...getTextToResizeObj,
                x: node.x(),
                y: node.y(),
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              },
            ]);
          }
        }}
      />
      {isSelected && selectedInputId === data.id && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}

export default BarCodeGenerator;
