export const SPACING_SAME_LINE = 8;
export const SPACING_PARAGRAPH = 13;
export const FONT_SIZE = 13
export const POSSIBLE_FONT_FAMILLY=[
    "Courier",
    //"Courier-Bold",
    /*"Courier-BoldOblique",
    "Courier-Oblique",
    "Helvetica",
    "Helvetica-Bold",
    "Helvetica-BoldOblique",
    "Helvetica-Oblique",
    "Symbol",
    "Times-Roman",
    "Times-Bold",
    "Time-Italic",
    "Time-BoldItalic",
    "Kavivanar",*/
    "century Gothic",
    "Ubuntu",
    "RobotoMono",
    "EduNSWACTFoundation",
    "DancingScript",
]

export const SPACING_FONT_SIZE_By_Y={
    32:31,
    28:25,
    24:20,
    20:16,
    16:12,
    14:10,
}